<template>
    <div>
        <SurveyHeader title="Dashboard"/>
        <v-container style="margin-top: 40px">
            <div class="col-md-12" v-if="getRole() === 'admin'">
                <v-expansion-panels accordion>
                    <v-expansion-panel>
                    <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
                        <div class="text-center font-weight-bold">
                        Filters
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form ref="searchForm" v-on:submit.prevent="hitAdvanceSearch(false)">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                            hide-details
                                            outlined
                                            label="From Date"
                                            dense v-model="searchFormData.fromDate"
                                            type="date"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                            hide-details
                                            outlined
                                            label="To Date"
                                            dense v-model="searchFormData.toDate"
                                            type="date"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                            <button :disabled="loading" type="submit" style="width: 100%"
                                    class="btn btn-success theme-btn ml-auto mr-2">
                                Search
                                <v-icon class="ml-2" small>fas fa-search</v-icon>
                            </button>
                            </v-col>
                            <v-col cols="12" sm="6">
                            <button type="button" :disabled="loading" style="width: 100%" @click="hitAdvanceSearch(true)"
                                    class="btn btn-primary ml-auto">
                                Reset
                                <v-icon class="ml-2" small>fas fa-times</v-icon>
                            </button>
                            </v-col>
                        </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <div class="bg-light-success text-center pt-3 pb-3" style="background-color: #ffffff !important;">
                        <p class="font-weight-bold font-size-h3 mb-3">
                            Treatment Surveys
                        </p>
                        <p class="font-weight-bold font-size-h6 m-0">
                            {{ tForms }} / 11250 ({{ tPer }}%)
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="bg-light-success text-center pt-3 pb-3" style="background-color: #ffffff !important;">
                        <p class="font-weight-bold font-size-h3 mb-3">
                            Control Surveys
                        </p>
                        <p class="font-weight-bold font-size-h6 m-0">
                            {{ cForms }} / 3750 ({{ cPer }}%)
                        </p>
                    </div>
                </div>
            </div>
            <v-divider class="mb-0"></v-divider>
            <v-row>
                <v-col cols="12">
                    <v-card-title class="pt-0 pl-0">
                        Division wise Treatment Surveys
                    </v-card-title>
                    <div class="bg-white">
                        <apexchart ref="divisionTGraph" type="line" height="350" :options="chartOptions2"
                                   :series="series2"></apexchart>
                    </div>
                </v-col>
            </v-row>
            <v-divider class="mb-0"></v-divider>
            <v-row>
                <v-col cols="12">
                    <v-card-title class="pt-0 pl-0">
                        Division wise Control Surveys
                    </v-card-title>
                    <div class="bg-white">
                        <apexchart ref="divisionCGraph" type="line" height="350" :options="chartOptions2"
                                   :series="series2"></apexchart>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-select
                            :items="divisions"
                            item-text="value"
                            item-value="value"
                            label="Division"
                            @change="divisionChange($event)"
                            dense
                            outlined
                            hide-details
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-card-title class="pt-0 pl-0">
                        District wise Treatment Surveys
                    </v-card-title>
                    <div class="bg-white">
                        <v-data-table
                                :headers="headers1"
                                :items="items1"
                                height="500px"
                                mobile-breakpoint="100"
                                class="elevation-0"></v-data-table>
                    </div>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-card-title class="pt-0 pl-0">
                        Tehsil wise Treatment Surveys
                    </v-card-title>
                    <div class="bg-white">
                        <v-data-table
                                :headers="header3"
                                :items="items2"
                                height="500px"
                                mobile-breakpoint="100"
                                class="elevation-0"></v-data-table>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-card-title class="pt-0 pl-0">
                        District wise Control Surveys
                    </v-card-title>
                    <div class="bg-white">
                        <v-data-table
                                :headers="headers2"
                                :items="items3"
                                height="500px"
                                mobile-breakpoint="100"
                                class="elevation-0"></v-data-table>
                    </div>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-card-title class="pt-0 pl-0">
                        Tehsil wise Control Surveys
                    </v-card-title>
                    <div class="bg-white">
                        <v-data-table
                                :headers="header4"
                                :items="items4"
                                height="500px"
                                mobile-breakpoint="100"
                                class="elevation-0"></v-data-table>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

import SurveyHeader from "@/view/pages/realstate/inc/Header";
import {GET_DASHBOARD_STATS, GET_DIVISION_STATS} from "@/core/services/store/survey.module";
import apexchart from "vue-apexcharts";

export default {
    name: "dashboard",
    components: {
        SurveyHeader,
        apexchart
    },
    data() {
        return {
            search: '',
            headers1: [
                {text: 'District Name', value: 't_s_2_4'},
                {text: 'Surveys', value: 'count'},
            ],
            headers2: [
                {text: 'District Name', value: 'c_s_2_4'},
                {text: 'Surveys', value: 'count'},
            ],
            header3: [
                {text: 'Tehsil Name', value: 't_s_2_4_1'},
                {text: 'Surveys', value: 'count'},
            ],
            header4: [
                {text: 'Tehsil Name', value: 'c_s_2_4_1'},
                {text: 'Surveys', value: 'count'},
            ],
            items1: [],
            items2: [],
            items3: [],
            items4: [],
            cForms: 0,
            tForms: 0,
            tPer: '',
            cPer: '',
            series2: [
                {
                    name: '',
                    type: 'column',
                    data: []
                },
                {
                    name: '',
                    type: 'line',
                    data: []
                }
            ],
            chartOptions2: {
                plotOptions: {
                    bar: {
                        distributed: true,
                    }
                },
                tooltip: {
                    enabled: false
                },
                chart: {
                    type: 'line',
                    stacked: false
                },
                stroke: {
                    width: [1]
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [0]
                },
            },
            searchFormData: {fromDate: '', toDate: ''}
        }
    },
    mounted() {
        this.getDashboardStats();
    },
    methods: {
        getDashboardStats() {
            this.$store.dispatch(GET_DASHBOARD_STATS, {data: this.searchFormData}).then((res) => {
                this.tForms = res.records.tForms
                this.cForms = res.records.cForms
                let tfv = (res.records.tForms / 11250) * 100;
                this.tPer = tfv.toFixed(2)
                let cfv = (res.records.cForms / 3750) * 100;
                this.cPer = cfv.toFixed(2)
                this.$refs.divisionTGraph.updateOptions(
                    {xaxis: {categories: res.records.divisionTGraph.title}}
                )
                this.$refs.divisionTGraph.updateSeries([
                    {data: res.records.divisionTGraph.count},
                    {data: res.records.divisionTGraph.count}
                ])
                this.$refs.divisionCGraph.updateOptions(
                    {xaxis: {categories: res.records.divisionCGraph.title}}
                )
                this.$refs.divisionCGraph.updateSeries([
                    {data: res.records.divisionCGraph.count},
                    {data: res.records.divisionCGraph.count}
                ])
                this.items1 = res.records.districtTTable
                this.items2 = res.records.tehsilTTable
                this.items3 = res.records.districtCTable
                this.items4 = res.records.tehsilCTable
            })
        },
        divisionChange(e) {
            this.$store.dispatch(GET_DIVISION_STATS, {division: e, data: this.searchFormData}).then((res) => {
                this.items1 = res.records.districtTTable
                this.items2 = res.records.tehsilTTable
                this.items3 = res.records.districtCTable
                this.items4 = res.records.tehsilCTable
            })
        },
        hitAdvanceSearch(reset = false) {
            if (reset) {this.searchFormData = {fromDate: '', toDate: ''}}
            this.getDashboardStats()
        },
    }
};
</script>
